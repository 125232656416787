.carousel-indicators {
    position: relative;
}
.carousel-item {
    max-height: 350px !important;
    min-height: 350px !important;
    overflow: hidden;
}
@media all and (max-width: 992px) {
    .carousel-item {
        max-height: 600px !important;
        min-height: 600px !important;
        overflow: hidden;
    }
}
.carousel-item img {
    max-height: 150px;
    margin: auto;
}
.carousel-indicators {
    width: fit-content;
    margin: auto;
    border-radius: 50px !important;
    padding: 1px 5px;
}
.carousel-indicators li {
    text-indent: initial;
    height: auto;
    border-radius: 50%;
    text-align: center;
    padding: 3px 0px !important;
}
.map-container iframe {
    width: 100%;
}
.bg-rayure {
    background-image: url('../img/background-rayure.jpg');
}
footer ul {
    list-style: none;
}
footer ul li {
    text-transform: uppercase;
    margin: 5px 0px;
}